@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-input {
  @apply mt-1 block w-full border-gray-300 rounded-md;
}

.theme-error {
  @apply text-red-500;
}

.theme-submit-button {
  @apply md:col-start-1 md:col-span-1 bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold py-2 px-4 mb-4 rounded;
}

.theme-nav-title {
  @apply text-lg md:mt-0 md:text-2xl font-bold;
}

.result-table-border {
  @apply border p-2;
}

.textDate {
    align-items: center;
    height: 32px;
    display: flex;
}

.ant-message-custom-content {
    align-items: center;
    display: flex;
}

.formEditUser .ant-form-item-control-input-content {
    position: relative;
}

.formEditUser .inputDateLabel {
    position: absolute;
    top: -20px;
}

.list-exam {
    gap: 18px;
    margin-top: 47px;
}

.list-exam div {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 65px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    color: #C4C4C4;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
}

.list-exam .font-run{
    font-size: 16px;
}

.list-exam .exam-active {
    background: #036EB8;
    border: none;
    color: #FFFFFF;
}

.rating-level {
    display: flex;
    padding: 2px 0;
    margin: 64px auto 55px;
    align-items: center;
    width: 400px;
    height: 40px;
}
.rating-level .mr-10 {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    color: #036EB8;
}

.list-video .ant-tag {
    height: 24px;
    width: 64px;
    border-radius: 2px;
    margin-top: 4px;
    border: none;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
}

.select-show-result {
    display: flex;
    justify-content: center;
    margin: 16px 0 20px;
}

.result-item {
    width: 320px;
    height: 54px;
    margin: 0 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    background: #036EB8;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.result-item.result-active {
    background: #FFC833;
}

.icon-play-video {
    width: 64px;
    height: 32px;
    background: #036EB8;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 11px 0;
}
.icon-play-video img {
    width: 24px;
    height: 24px;
}

.title-test {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.rating-comment {
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-top: 5px;
}

.table-right {
    scrollbar-width: none;
}

.table-right::-webkit-scrollbar {
    display: none;
}

.list-exam-graph {
    gap: 18px;
    margin-top: 47px;
}

.list-exam-graph div {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    color: #C4C4C4;
    cursor: pointer;
}

@media screen and (min-width: 541px) {
    .list-exam-graph div {
        width: 110px;
    }
}

.list-exam-graph .exam-active {
    background: #036EB8;
    border: none;
    color: #FFFFFF;
}

.custom-tooltip {
    background: #FFFFFF;
    border: 1px solid #036EB8;
    border-radius: 4px;
    text-align: center;
    width: 100px;
    height: 80px;
}

.custom-tooltip .label {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    padding-top: 17px;
}

.custom-tooltip .desc {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #025FA1;
    margin-top: 16px;
}

.form-content {
    display: flex;
}

.menu-dashboard {
    margin-top: 5rem;
    min-width: 80px;
}

.menu-dashboard .menu-item {
    background: #90A2C3;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.menu-dashboard .menu-item img {
    width: 40px;
    height: 40px;
    margin: 0 auto 8px;
}

.form-children {
    max-height: calc(100vh - 5rem);
    overflow: auto;
    margin: 80px 0 0 80px;
    max-width: calc(100vw - 80px);
    width: calc(100vw - 80px);
}

.menu-dashboard .item1 {
    background: #366CA8;
}

.menu-dashboard .item2 {
    background: #5179AF;
}

.menu-dashboard .item3 {
    background: #6886B5;
}

.menu-dashboard .item4 {
    background: #7C94BC;
}

.menu-dashboard .menu-item.menuActive {
    background: #FFC833;
}

.show-pc {
    display: block;
}

.show-sp {
    display: none;
}

.display-pc {
    display: block !important;
}

.display-sp {
    display: none !important;
}

.video-item {
    width: 966px;
}

.form-result {
    width: 1240px;
    min-height: 611px;
    margin-bottom: 12px;
}

#core-table .table-core-result {
    min-width: 828px;
}

.modal-mypage {
    width: 800px;
}

.modal-mypage .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 0;
    display: flex;
}

.modal-mypage .inputDateLabel {
    position: absolute;
    top: -20px;
    left: 4px;
}

.table-question {
    display: flex;
}

.table-question .table-graph {
    padding: 22px 66px;
}

.image-dropdown-question {
    width: 32px;
    height: 20px;
}

.video-item-list {
   max-width: 1200px;
}

.menu-dashboard-sp {
    display: none;
}

.show-answer-item ruby.font-black {
    font-weight: 500;
}

.option-question div.ant-col {
    display: flex;
    align-items: flex-end;
}

.show-notify-register-group {
    width: calc(50vw - 380px);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
    padding: 4px 12px;
    background: #ffebcc;
    border: 2px solid;
    border-radius: 5px;
    display: inline-flex;
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 40;
}

@media screen and (max-width: 1000px) {
    .video-page {
        width: 50% !important;
    }
    .show-notify-register-group {
        width: max-content;
        position: relative;
        padding: 8px;
        font-size: 16px;
        line-height: 20px;
        top: unset;
        margin: auto;
        text-align: center;
        justify-content: center;
        display: flex;
    }
}

@media screen and (max-width: 688px) {
    .video-page {
        width: 100% !important;
    }
}

@media screen and (min-width: 1399px) {
    .video-page {
        width: 25% !important;
    }
}

.w-13\/20 {
    width: 65%;
}

.w-7\/20 {
    width: 35%;
}

@media (max-width: 1028px) {
    .video-item {
        width: 100%;
    }
    .video-item .w-1\/3 {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .form-test-input .form-weight-height .ant-col-6 {
        max-width: 50%;
        margin-right: 10px;
    }

    .form-test-input .form-test {
        display: block;
    }

    .form-test-input .form-test-item {
        max-width: 100%;
        padding: 0;
    }

    .form-result .form-result-item {
        display: block;
    }

    .form-result .form-result-item .w-13\/20, .form-result .form-result-item .w-7\/20 {
        width: 100%;
        padding: 0;
    }

    .select-show-result .result-item {
        font-size: 18px;
    }

    .form-children .form-result {
        padding: 4px 4px 12px;
        border-radius: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 868px) {

    .video-item-list {
        width: 100%;
    }
    .list-video .w-1\/3  {
        width: 50%;
    }
    .table-question {
        display: block;
    }
    .form-children .form-question {
        width: calc(100vw - 100px);
        margin: auto;
        overflow-x: hidden;
    }
    .form-question .form-question-content {
        width: calc(100vw - 100px)!important;
    }
    .form-question .ant-list-item-no-flex {
        width: calc(100vw - 120px) !important;
    }
    .list-exam div {
        width: 150px;
        height: 50px;
    }
    #core-table {
        padding: 8px;
    }
    #core-table .table-core-result {
        min-width: 620px;
    }
    .modal-mypage {
        width: 620px;
    }
    .modal-mypage .inputBody {
        padding: 0;
        text-align: center;
    }

    .list-exam-graph {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width: 540px) {
    .show-notify-register-group {
        font-size: 14px;
        line-height: 18px;
        max-width: 100vw;
    }
    .image-dropdown-question {
        width: 16px;
        height: 10px;
    }

    .show-pc {
        display: none;
    }

    .display-pc {
        display: none !important;
    }

    .display-sp {
        display: block !important;
    }

    .show-sp {
        display: block;
    }

    .form-content {
        flex-direction: column;
        padding: 64px 0;
    }

    .h-screen {
        height: max-content;
    }

    .menu-dashboard-sp {
        position: fixed;
        bottom: 0;
        margin-top: 0;
        z-index: 11;
        display: block;
        background-color: #366CA8;
    }

    .menu-dashboard .menu-item {
        width: calc(20vw);
        height: 62px;
        background: #366CA8;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
    }

    .menu-dashboard .menu-item img {
        width: 24px;
        height: 24px;
        margin: 10px auto 4px;
    }

    .form-children {
        max-height: none;
        margin: 0 auto;
        max-width: 100vw;
        padding-bottom: 0;
        width: 100vw;
    }

    .menu-dashboard .menu-item.menuActive {
        background: #FFC833;
    }

    .tag-item {
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
    }

    .tag-item .tag-desc {
        font-size: 14px;
        line-height: 14px;
    }

    .list-exam {
        gap: 4px;
        margin: 12px 0;
        padding: 0 4px;
    }

    .rating-level {
        width: 100%;
        margin: 0;
        padding: 0 12px;
        justify-content: space-between;
    }

    .list-exam div {
        width: 100%;
        height: 30px;
        font-size: 10px;
        line-height: 12px;
    }

    .list-exam .text-14-hina {
        font-size: 6px !important;
        line-height: 6px !important;
    }

    .list-exam-graph {
        gap: 4px;
    }

    .list-exam-graph div {
        width: 100%;
        height: 30px;
        font-size: 10px;
        line-height: 12px;
    }

    .list-exam-graph .text-xxs {
        font-size: 6px !important;
        line-height: 6px !important;
    }

    .videos-page .text-14-hina {
        font-size: 6px !important;
        line-height: 6px !important;
    }
    .select-show-result .text-14-hina {
        font-size: 8px !important;
        line-height: 8px !important;
    }

    .list-exam .font-run {
        font-size: 10px;
        line-height: 8px;
    }

    .form-test-input .form-input-run {
        display: block;
    }

    .form-test-input .form-input-run .input-run-item {
        max-width: 100%;
    }

    .form-test-input .form-input-run .input-run-measure {
        max-width: 100%;
    }

    .form-test-input .button-memo {
        margin-left: -8px;
    }

    .form-test-input .title-test-sp {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #333333;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .form-test-input .title-test-sp img {
        width: 19px;
        height: 20px;
        margin-left: 9px;
    }

    .title-last-year {
        background: #036EB8;
        border-radius: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        padding: 4px 16px;
        color: #ffffff;
    }

    .form-test-sp {
        width: max-content;
        margin: 12px auto 0;
    }

    .form-test-sp .button-memo {
        width: 160px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
    }

    .form-children .form-question {
        width: calc(100vw - 24px);
        margin: 12px auto;
        padding: 0;
    }

    .form-children .form-question .ant-dropdown-trigger {
        font-size: 18px;
        line-height: 22px;
    }

    .table-question .table-graph {
        padding: 12px 0;
    }

    .form-question .text-result {
        font-size: 14px !important;
        line-height: 16px !important;
    }

    .form-menu .text-result {
        font-size: 14px !important;
        line-height: 16px !important;
    }

    .form-menu {
        max-height: calc(100vh - 200px);
        overflow: auto;
        margin: 0 12px !important;
    }

    .form-question .form-question-content {
        width: calc(100vw - 24px)!important;
    }
    .form-question .ant-list-item-no-flex {
        width: calc(100vw - 40px) !important;
        padding: 4px 0;
    }

    .form-question .option-question {
        display: block;
    }

    .form-question .option-question div.ant-col {
        margin: 2px 0;
    }

    .select-show-result .result-item {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        width: 120px;
        height: 30px;
        padding: 9px 0;
    }

    .form-result-item .result-table .ant-col-12 {
        max-width: 100%;
        margin: auto;
        padding: 0;
    }

    .form-result .form-result-item .w-7\/20 {
        padding: 12px;
    }

    .form-result .form-result-item .w-7\/20 .p-16.space-y-2.bg-white.h-full {
        padding: 12px;
    }

    .form-result .result-graph {
        padding: 0;
    }

    .form-children .form-result {
        display: block;
        border-radius: 0;
        padding: 12px;
    }

    .list-exam-graph-test {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .list-exam-graph-test {
        gap: 4px;
        margin-top: 24px;
    }

    .list-exam-graph-test div {
        font-size: 12px;
        line-height: 14px;
        width: 100%;
    }

    .show-data-test {
        padding: 4px;
    }

    .rating-comment {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        display: block;
    }

    .form-table-sp {
        overflow: auto;
        max-width: calc(100vw - 40px);
    }

    .modal-mypage {
        width: calc(100vw - 24px);
    }

    .modal-mypage .inputBody {
        padding: 0;
        text-align: center;
    }

    .welcome-text-en {
        font-size: 60px !important;
    }

    .welcome-background {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
    }

    .register-page {
        height: 100vh;
    }

    .welcome-background .welcome-button-wrapper {
        position: relative;
        padding-top: 24px;
        display: flex;
        justify-content: center;
        left: 0;
    }

    .welcome-background .welcome-button-wrapper .welcome-button {
        max-width: calc(100vw - 32px);
    }

    .welcome-background .agreement-text {
        padding-top: 0;
    }

    .welcome-background .welcome-sponsor {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
    }
}

@media (max-width: 401px) {
    .chart-result {
        margin-left: -1.25rem;
        margin-right: -1.25rem;
    }
}

@media (max-width: 361px) {
    .chart-result {
        margin-left: -3rem;
        margin-right: -2.5rem;
    }
}

.color-red-i {
  color: #b80303 !important;
}

.color-blue-f {
  color: #036eb8 !important;
}

.border-color-blue {
  border-color: #036eb8;
}

.color-gray3 {
  color: #333333;
}

.text-12px {
  font-size: 12px !important;
  line-height: 14px !important;
}

.py-11px {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.py-8px {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.text-tiny-i {
  font-size: 6px !important;
  line-height: 9px !important;
}

.border-3px {
  border-width: 3px;
}

@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-up-down {
  animation: upDown 1s ease-in-out infinite;
}
